var setCookie = function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
var getCookie = function getCookie(name) {
  var matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
document.addEventListener('DOMContentLoaded', function () {
  var popup = document.querySelector('.popup');
  var closeBtn = popup.querySelector('.popup__btn_close');
  var allowBtn = popup.querySelector('.popup__btn_allow');
  if (!getCookie('cookies_policy')) {
    popup.classList.add('popup_show');
  }
  allowBtn.addEventListener('click', function () {
    setCookie('cookies_policy', 'true', 365);
    popup.classList.remove('popup_show');
  });
  closeBtn.addEventListener('click', function () {
    popup.classList.remove('popup_show');
  });
});