document.addEventListener('DOMContentLoaded', function () {
  var scrollToSection = function scrollToSection(targetName, targetSectionOffsetTop) {
    var targetSection = document.getElementById(targetName);
    if (targetSection) {
      var topOfTargetSection = targetSection.offsetTop - targetSectionOffsetTop;
      window.scrollTo({
        top: topOfTargetSection,
        behavior: 'smooth'
      });
    }
  };
  var menuLinks = document.querySelectorAll('.header__mobile-menu-link');
  var headerLogo = document.querySelector('.header__logo');
  var menuBtn = document.querySelector('.header__mobile-menu-button');
  var mobileMenu = document.querySelector('.header__mobile-menu');
  menuLinks.forEach(function (link) {
    link.addEventListener('click', function (event) {
      event.preventDefault();
      headerLogo.classList.remove('active');
      menuBtn.classList.remove('active');
      mobileMenu.classList.remove('active');
      var body = document.body;
      var scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      var targetId = this.getAttribute('href').slice(1);
      scrollToSection(targetId, 80);
    });
  });
});